import { Queue } from './usePageTab'



const useFootPrint = () => {
    function getNowStr() {
        const date = new Date();
        const Y = date.getFullYear() + '-';
        const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
        const D = date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate();

        return Y + M + D;
    }
    function switchData(footPrint: any) {
        return {
            type: footPrint.type || 'topic',
            title: footPrint.title,
            pic: footPrint.pic || '',
            img: footPrint.img || '',
            url: footPrint.url || '',
            info: footPrint.info,
            brand: footPrint.brand,
            catno: footPrint.catno,
            id: footPrint.proid || footPrint.bnccid,
            speciesGenus: footPrint.speciesGenus,
            specification: footPrint.specification,
            suppliercode: footPrint.suppliercode,
            concentration: footPrint.concentration,
            addtime: getNowStr(),
            summary: footPrint.summary || ''
        }

    }

  
    const footQueue = new Queue()
    function getFootItem() {
        const footPrint = localStorage.getItem('footPrint')

        const list = footPrint ? JSON.parse(footPrint) : [];
        footQueue.clear()
        list.forEach((item: any) => {
            footQueue.enQueue(item)
        });

    }
    function setFootItem(footPrint: any) {
        if (!footPrint) {
            return false
        }
        getFootItem()
       
        footQueue.enQueue(switchData(footPrint))
        localStorage.setItem('footPrint', JSON.stringify(footQueue.value()))
    }
    


    return {
        setFootItem,
        getFootItem,
        footQueue
    }


}

export default useFootPrint