
import { defineComponent, PropType } from "vue";

import api from "@/api/axios";
import Toast from "@/components/UI/Toast/";
import useShopCar from "@/hooks/useShopCar";
import $ from "jquery";

export default defineComponent({
  name: "slotFooter",
  props: {
    tid: {
      type: String,
      default: "",
    },
    nclassId: {
      type: String,
      default: "0",
    },
    pid: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "1",
    },
    isFav: {
      type: Boolean,
      default: false,
    },
    viewCartText: {
      type: String,
      default: "View shopping cart",
    },
    lotid: {
      type: String,
      default: "0"
    },
    tcid: {
      type: String,
      default: "0"
    },
    stock: {
      type: Number,
      default: null
    }
  },
  setup(props) {
    //  add shop car
    const { addShopCar } = useShopCar();
    function handleAddCart() {
      const formData = new FormData();
      formData.append("pid", props.pid);
      formData.append("tid", props.tid);
      formData.append("lotid", props.lotid);
      formData.append("tcid", props.tcid);
      formData.append("qty", "1");
      formData.append("countryType", "1");
      api
        .post("/M/Server/AddToCart", formData)
        .then((res): void => {
          Toast({
            title: res.data.msg,
            type: "success",
          });
        })
        .catch((err) => {
          Toast({
            title: err.msg || "加入购物车失败",
            type: "error",
          });
        });
    }


      function chat() {
          window.open(
              "https://tb.53kf.com/code/client/670c56f26e85ec66df9d068b5c2cd2531/1",
              "_blank",
              "top=300,left=500,width=800,height=600,menubar=no,toolbar=no,status=no,scrollbars=yes"
          );
          return false;
      }




    return {
      handleAddCart,
      addShopCar,
      chat,
    };
  },
});
