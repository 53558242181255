
import {defineComponent, ref, reactive, watch, inject, onMounted, nextTick, onUnmounted} from "vue";
import { useRoute, useRouter } from "vue-router";
import api from "@/api/axios";
import axios from "@/api/axios";
import useFootPrint from "@/hooks/useFootPrint";
import SlotFooter from "@/components/common/SlotFooter.vue";
import Loading from "@/components/UI/Loading";
import navigatorType from "@/utils/browser";
import asyncLoadJs from "@/hooks/useAsyncLoadJs";
import vTCPlayer from "@/hooks/useTcplayer";
import Toast from "@/components/UI/Toast";
import Modal from "@/components/UI/Modal";


export default defineComponent({
  name: "productdetail",
  components: {
    SlotFooter,
    Loading,
  },
  setup() {
    const shareTitle = ref('')
    const route = useRoute();
    const router = useRouter();
    const id = ref(route.params.id);
    const classId = ref(route.params.classId);
    const isFav = ref(false);
    const productsVO = ref(null);
    const compList = ref([]);
    const RelatedProIdList = ref([]);
    const { setFootItem } = useFootPrint();
    const listCompLen = ref(0);
    const  productLotList = ref()
    const lotid = ref()
    const tcid = ref(0)
    const specChecked = ref(true)
    const productPrice = ref()
    const productStock = ref()
    const biaowuPriceOpen = ref(0)
    const productPdfHref = ref()
    const isLogin = ref();
    const token = localStorage.getItem('token') || '';
    if(!token){
      isLogin.value = false;
    }else{
      isLogin.value = true;
    }
    //  展示用户信息
    const userInfo = inject("userInfo") as any;
    let VideoPlayer: any = null;
    const videoFileId = ref();
    const shopShow = ref(true)
    function getData(id: any) {
      productsVO.value = null;
      api
        .get("/M/Product/ProductDetail/" + id)
        .then((res): void => {

          if(res.data.success){

            const data = res.data.obj;
            biaowuPriceOpen.value = data.biaowuPriceOpen
            // if (data.productsVO.nongdu && data.productsVO.nongdu.indexOf('(') != -1) {
            //   const index = data.productsVO.nongdu.indexOf('(')
            //   data.productsVO.nongdu = data.productsVO.nongdu.substring(0, index)
            // }
            productsVO.value = data.productsVO;
            productPdfHref.value = data.productPdfHref
            /*document.title:浏览器title*/
            document.title = data.productsVO.enName + " | Weiye";
            shareTitle.value = data.productsVO.enName
            route.meta.title = data.productsVO.enName
            compList.value = data.listComp;
            isFav.value = data.isFav;
            RelatedProIdList.value = data.RelatedProIdList;
            listCompLen.value = data.listComp.length;
            data.productLotList.length>1 && data.productLotList.forEach((item: any) => {
              item.specChecked = false
            })
            if(data.productLotList.length>1){
              data.productLotList[0].specChecked = true;
              lotid.value = data.productLotList[0].lotid
            }
            productLotList.value = data.productLotList
            const isPc = navigatorType();
            if (isPc) {
              window.location.href = `https://www.weiyel.com/p_${data.productsVO.classId}/p_${data.productsVO.id}.html`;
            }
            if(data.productVideoId){
              videoFileId.value =data.productVideoId;
            }else{
              videoFileId.value ="5285890814547790536";
            }
            nextTick(() => {
              asyncLoadJs(
                  "//imgcache.qq.com/open/qcloud/video/tcplayer/lib/hls.min.0.8.8.js",
                  "ProductDetail"
              ).then(() => {
                asyncLoadJs(
                    "//imgcache.qq.com/open/qcloud/video/tcplayer/tcplayer.min.js",
                    "ProductDetail"
                ).then((res) => {
                  VideoPlayer = vTCPlayer(
                      "player-container-id",
                      videoFileId.value,
                      false
                  );
                  VideoPlayer.one('play',function () {
                    shopShow.value = false;
                  });
                });
              });
            })

            // 判断价格
            if (productLotList.value.length>1) {
              productStock.value = data.productLotList[0].stock
              productPrice.value = Math.ceil(Number(data.productLotList[0].outPrice))
            }else{
              productStock.value = (productsVO.value as any).stock
              productPrice.value = Math.ceil(Number((productsVO.value as any).retailPrice))
            }

            /**
             *  存足迹
             */
            setFootItem({
              type: "product",
              img: (productsVO.value as any).switchPic,
              title: (productsVO.value as any).cnname,
              info: (productsVO.value as any).catno,
              proid: (productsVO.value as any).id,
              brand: (productsVO.value as any).suppliercode
                  ? "Standard value" + (productsVO.value as any).suppliercode + "Components"
                  : (productsVO.value as any).suppliercode,
              specification: (productsVO.value as any).specification,
              concentration: (productsVO.value as any).concentration,
              url: { name: "productdetail", params: { id: route.query.id } },
            });
          }else {
            Modal({
              title: "error",
              content: res.data.msg,
              optionControl:2,
              confirmText:"OK",
              onConfirm: () => {

                router.push({
                  name: "product",
                });
              },
            });
          }

        })
        .catch((err) => {
          console.log(err);
        });
    }
    getData(id.value);

    function clickSpec(item: any, index: any) {
      productStock.value = item.stock
      lotid.value = item.lotid;

      productLotList.value.length>1 && productLotList.value.forEach((item: any) => {
        item.specChecked = false
      })
      productLotList.value[index].specChecked = true
      specChecked.value = false
      productPrice.value = Math.ceil(Number(item.outPrice))

    }
    function firstSpec() {
      if(!productLotList.value[0]) {
        return false
      }
      lotid.value = productLotList.value[0].lotid
      specChecked.value = true
      productLotList.value.length>0 && productLotList.value.forEach((item: any) => {
        item.specChecked = false
      })
    }
    watch(
      [
        () => {
          return route.query;
        },
        () => {
          return route.params;
        },
      ],
      (to, from) => {
        // 避免登录时触发页面刷新
        // console.log(to, from);
        if (to[1].id && !from[1].valid && !from[1].validate) {
          // VideoPlayer.dispose()
          videoFileId.value = "";
          getData(to[1].id as string);
        }
      }
    );
    onUnmounted(() => {
      // VideoPlayer.dispose()
      videoFileId.value = "";
    })
    const loadOption = {
      text: "Loading...",
      color: "#df0024",
      textColor: "#df0024",
    };
    return {
      productsVO,
      compList,
      id,
      classId,
      isFav,
      RelatedProIdList,
      loadOption,
      listCompLen,
      productLotList,
      clickSpec,
      specChecked,
      firstSpec,
      lotid,
      tcid,
      productPrice,
      biaowuPriceOpen,
      productPdfHref,
      productStock,
      videoFileId,
      isLogin,
      shopShow
    };
  },
});

