declare let TCPlayer: any;

const vTCPlayer = function (dom: string, fileId: string, autoplay: boolean) {
    return TCPlayer(dom, {
        fileID: fileId,
        appID: "1256414354",
        autoplay: autoplay,
    });
}

declare let WX: any;

const WXF = function (wx: any) {
    return wx
}

export default vTCPlayer
