<template>
  <footer>
    <ul class="footer-nav">
      <li class="footer-li">
<!--        <a-->
<!--          href="https://tb.53kf.com/code/client/670c56f26e85ec66df9d068b5c2cd2531/1"-->
<!--        >-->
          <a @click="chat()">
          <!-- <img src="https://www.bzwz.com/static/m/images/icon52.png" alt /> -->
          <img src="@/assets/img/common/icon52.png" alt="" />
          Chat
        </a>
      </li>
      <li
        id="add"
        :class="{
          'footer-li button': true,
          buttonHide: viewCartText != 'View shopping cart',
        }"
        style="background-color: #df0024;"
      >
        
        <!--addShopCar(id: string, tid: number, qty: number, lotid: number, tcid: number, stock: number, nclassId: number)-->
        <a href="javascript:;" @click="addShopCar(pid, Number(tid), 1 , lotid,tcid, stock,nclassId,1)"
          >Add to cart</a
        >
      </li>
      <li
        class="footer-li button"
        style="margin-right: 0"
      >
        <router-link
          :to="{ name: 'ShopCar' }"
          v-if="viewCartText == 'View shopping cart'"
          >{{ viewCartText }}</router-link
        >
        <a href="javascript:;" v-else @click="standarddownloadcheck">{{
          viewCartText
        }}</a>
      </li>
    </ul>
    <img src="@/assets/img/common/icon53_1.png" id="collectImg" v-show="false" alt="">
  </footer>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

import api from "@/api/axios";
import Toast from "@/components/UI/Toast/";
import useShopCar from "@/hooks/useShopCar";
import $ from "jquery";

export default defineComponent({
  name: "slotFooter",
  props: {
    tid: {
      type: String,
      default: "",
    },
    nclassId: {
      type: String,
      default: "0",
    },
    pid: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "1",
    },
    isFav: {
      type: Boolean,
      default: false,
    },
    viewCartText: {
      type: String,
      default: "View shopping cart",
    },
    lotid: {
      type: String,
      default: "0"
    },
    tcid: {
      type: String,
      default: "0"
    },
    stock: {
      type: Number,
      default: null
    }
  },
  setup(props) {
    //  add shop car
    const { addShopCar } = useShopCar();
    function handleAddCart() {
      const formData = new FormData();
      formData.append("pid", props.pid);
      formData.append("tid", props.tid);
      formData.append("lotid", props.lotid);
      formData.append("tcid", props.tcid);
      formData.append("qty", "1");
      formData.append("countryType", "1");
      api
        .post("/M/Server/AddToCart", formData)
        .then((res): void => {
          Toast({
            title: res.data.msg,
            type: "success",
          });
        })
        .catch((err) => {
          Toast({
            title: err.msg || "加入购物车失败",
            type: "error",
          });
        });
    }


      function chat() {
          window.open(
              "https://tb.53kf.com/code/client/670c56f26e85ec66df9d068b5c2cd2531/1",
              "_blank",
              "top=300,left=500,width=800,height=600,menubar=no,toolbar=no,status=no,scrollbars=yes"
          );
          return false;
      }




    return {
      handleAddCart,
      addShopCar,
      chat,
    };
  },
});
</script>

<style lang="scss" scoped>
footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50px;
  border-top: 1.012px solid #f4f4f4;
  font-size: 14px;
  color: #666;
  background-color: #fff;
  z-index: 100;
}

footer a {
  font-size: 14px;
  color: #666;
  display: block;
}

.footer-nav {
  position: relative;
}

.footer-li {
  float: left;
  width: 17%;
  text-align: center;
  position: relative;
}

.footer-li img {
  display: block;
  width: 20px;
  height: 20px;
  margin: 5px auto;
}

.footer-li .active-status {
  position: absolute;
  display: none;
  left: 50%;
  margin-left: -10px;
  top: 0;
  z-index: 10;
  background-color: #fff;
}

.footer-nav .active a {
  color: #df0024;
}

.footer-nav .active .active-status {
  display: block;
}

footer .button {
  width: 140px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  background-color: #e7c527;
  color: #fff;
  border-radius: 36px;
  margin-top: 8px;
  margin-right: 11px;
}

footer .button a {
  color: #fff;
}

.buttonHide {
  visibility: hidden;
}
</style>
